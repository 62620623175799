import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuth } from "../ContextAndHooks/AuthContext";
import {
  fetchData,
  generateRandomEmail,
  generateTransactionId,
  postData,
  removeMamParameter,
} from "../api/ClientFunction";
import Modal from "react-modal";
import { toast } from "react-toastify";
import useSWR from "swr";
import DepositeAmount from "./DepositeAccount";
import "./Deposit.css";
import axios from "axios";
const Deposit = () => {
  const [show, setShow] = useState(1);
  const { data, loading } = useSWR("/admin/getgatewaykey", fetchData);
  const [depositAmount, setDepositAmount] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isVideoModalShowing, setIsVideoModalShowing] = useState(false);
  useEffect(() => {
    if (data && data.data) {
      setShow(data.data.keyenable);
    }
  }, [data]);

  const location = useLocation();
  const [message, setMessage] = useState();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get("error");
    if (errorParam) {
      setMessage(errorParam);
    }
  }, [location.search]);

  useEffect(() => {
    if (
      message ===
      "Transaction Successful, Amount Has been added to your account!..."
    ) {
      toast.success(message);
    } else if (message) {
      toast.error(message);
    }
  }, [message]);

  const { user, gateWayKey } = useAuth();

  const { handleSubmit, setValue } = useForm({
    defaultValues: {
      depositAmount: "",
    },
  });

  const validate = () => {
    const errors = {};
    if (!depositAmount) {
      errors.depositAmount = "Deposit amount is required";
      toast.error("Deposit amount is required");
    } else if (depositAmount < 100) {
      errors.depositAmount = "Minimum deposit amount is 100";
      toast.error("Minimum deposit Amount is 100");
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const openUpiLink = (link, checkMobile) => {
    if (isMobile() || !checkMobile) {
      window.location.href = link;
    } else {
      alert(
        "Please open this link on a mobile device with a UPI app installed."
      );
    }
  };

  const isMobile = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    return /android|iPad|iPhone|iPod/i.test(userAgent);
  };
  const saveToLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value));
  };

  const getFromLocalStorage = (key) => {
    const value = localStorage.getItem(key);
    return value ? JSON.parse(value) : null;
  };

  const onSubmit = async (api_type) => {
    const transaction_id = generateTransactionId(user?.phone);
    if (!validate()) return;
    const depositData = {
      api_key: String(gateWayKey),
      user_phone: user?.phone,
      user_email: user?.email || generateRandomEmail(),
      user_name: user?.name || user?.name_user,
      amount: String(depositAmount),
      transaction_id,
      api_type,
    };

    try {
      const res = await postData("/user/proxy/create_order", depositData);
      if (res.status || res.success) {
        openUpiLink(removeMamParameter(res.data.authdata.payaddress, res.data.forMobile));
        saveToLocalStorage("transactionDetails", {
          transaction_id: res.data.transID,
          amount: depositAmount,
          phone: user?.phone,
        });
      } else {
        toast.error("Failed to create order.");
      }
    } catch (error) {
      toast.error("Failed to deposit amount. Please try again later.");
    }
  };

  const [allBonus, setAllBonus] = useState([]);
  const { data: adata } = useSWR("/admin/getallbonus", fetchData);
  useEffect(() => {
    if (adata && adata?.data) {
      setAllBonus(adata?.data || []);
    }
  }, [adata]);

  // CSS for hover effect (can be added to your CSS file)
  const styles = {
    cardHover: {
      "&:hover": {
        transform: "scale(1.05)", // Slightly enlarge on hover
      },
    },
  };
  const MoneyBox = ({ amount, bonus }) => (
    <div
      className="col-4 col-md-3 col-lg-2 p-1" // Adjusted for smaller size on different breakpoints
      onClick={() => {
        setDepositAmount(amount);
        setErrors({ ...errors, depositAmount: "" });
      }}
      style={{ cursor: "pointer" }}
    >
      <div
        className="card w-100 text-center shadow-sm border-0 money-box-card"
        style={{
          backgroundColor: amount == depositAmount ? "rgb(220 53 69)" : "#1a1a2e", // Darker card background
          color: "#fff", // White text color
          transition: "transform 0.2s", // Smooth hover effect
          padding: "0.5rem", // Reduced padding for a smaller card
        }}
      >
        <div
          className="card-body p-1" // Reduced padding inside the card
          style={{
            border: "1px solid #e94560", // Red border to match the dark theme
            borderRadius: "8px", // Rounded corners
          }}
        >
          <h6 className="card-title mb-1" style={{ fontSize: "0.9rem" }}>
            {amount} Rs
          </h6>{" "}
          {/* Smaller font size */}
          <p className="card-text small mb-0">+{bonus}% Bonus</p>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container my-5 text-white">
      {show === 1 && (
        <div className="d-flex justify-content-center">
          <form
            className="col-md-6"
            onSubmit={(e) => e.preventDefault()}
            noValidate
          >
            <div className="row mb-3 text-white align-items-end">
              <span className="col-7 text-end" style={{fontSize: "xx-large"}}>Deposit</span>
              <span className="col-5 text-end" style={{cursor: "pointer"}} onClick={() => setIsVideoModalShowing(true)}><span className="blink">How To Pay?</span></span>
            </div>

            <div className="row g-3">
              {allBonus.map(({ money, bonus }) => (
                <MoneyBox key={money} amount={money} bonus={bonus} />
              ))}
            </div>
            <div className="row g-3">
            <button onClick={()=> handleSubmit(onSubmit(2))} className="btn btn-primary w-50 mt-3 col-5">
              UPI-W {depositAmount}
            </button>
            <div className="col-1"></div>
            <button onClick={()=> handleSubmit(onSubmit(1))} className="btn btn-primary w-50 mt-3 col-5">
              UPI-O {depositAmount}
            </button>
            </div>
          </form>
        </div>
      )}
      <Modal
        isOpen={isLoading}
        onRequestClose={() => setIsModalOpen(false)}
        contentLabel="Payment Processing"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div className="modal-body">
          {isLoading && (
            <>
              <div className="loader"></div>
              <p>Processing your payment...</p>
              <p>
                Do not try to close this window or reload this page, Otherwise
                Your Funds will be lost
              </p>
            </>
          )}
        </div>
      </Modal>
      <Modal
        isOpen={isVideoModalShowing}
        onRequestClose={() => setIsVideoModalShowing(false)}
        contentLabel="Payment Processing"
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <span onClick={() => setIsVideoModalShowing(false)} style={{textAlign: "end", color: "#FFFFFF", cursor: "pointer", fontSize: "x-large"}}>X</span>
        <div className="modal-body">
            <video style={{height: "75vh", width: "100%"}} controls src="images/IMG_4048.MP4"></video>
        </div>
      </Modal>
      {show === 0 && <DepositeAmount />}
    </div>
  );
};

export default Deposit;
